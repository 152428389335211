import { createStore } from 'cross-state';
import { createBrowserHistory } from 'history';
import _ from 'lodash';
import { parse as qsParse, stringify as qsStringify } from 'qs';
import { useEffect } from 'react';
import { URLUtils } from './resolveUrl';
import { SingleUrl } from './url-types';
import { UrlState, removeFromBackHistory, urlChanged } from './url.action';
import { service } from '@whiz-cart/node-shared/service/service';

export const urlService = service(
    'urlService',
    class UrlService {
        history = createBrowserHistory({ getUserConfirmation: (...args) => this.getUserConfirmation(...args) });
        cancelListener?: () => void = undefined;
        store: any = null;
        hashBase = createStore<string | undefined>(undefined);

        parse = (s: string) =>
            _.mapValues(qsParse(s, { strictNullHandling: true }), (v) => (v === null ? true : v)) as { [key: string]: any };
        stringify = (o: { [key: string]: any }) =>
            qsStringify(
                _.mapValues(o, (v) => (v === true ? null : v === false ? undefined : v)),
                {
                    strictNullHandling: true,
                    arrayFormat: 'repeat',
                },
            );

        /** The browser history object. */
        getUserConfirmation = (message: string, callback: (result: boolean) => void) => callback(window.confirm(message));

        state = createStore<SingleUrl>(({ connect }) => {
            connect(({ set }) => {
                return this.history.listen(() => set(this.getUrl()));
            });

            return this.getUrl();
        });

        getUrlParams() {
            return this.parse(this.history.location.search.substring(1));
        }

        getUrlHashParams() {
            return this.parse(this.history.location.hash.substring(1));
        }

        /** Return the current url. */
        getUrl() {
            return {
                path: this.history.location.pathname,
                params: this.getUrlParams(),
                hash: this.getUrlHashParams(),
                raw: {
                    search: this.history.location.search,
                    hash: this.history.location.hash,
                },
            } as SingleUrl;
        }

        /** Calculte a new url from the current one, overriding the given path and/or params. */
        calcUrl(
            ...args:
                | [path: string, params?: Record<string, any>, hash?: Record<string, any>]
                | [params: Record<string, any>, hash?: Record<string, any>]
        ) {
            let path;
            let params;
            let hash;

            if (typeof args[0] === 'string') {
                [path, params, hash] = [args[0], args[1] as Record<string, any> | undefined, args[2] as Record<string, any> | undefined];
            } else {
                [path, params, hash] = ['', args[0] as Record<string, any> | undefined, args[1] as Record<string, any> | undefined];
            }

            path = this.resolve(this.history.location.pathname, path);
            const sameHashBase = this.isInCurrentHasBase(path);

            params = {
                ...this.getUrlParams(),
                ...(params instanceof Object ? params : undefined),
            };
            hash = {
                ...(sameHashBase ? this.getUrlHashParams() : undefined),
                ...(hash instanceof Object ? hash : undefined),
            };

            return this.urlToString({ path, params, hash });
        }

        /** Sets a new url. */
        pushUrl(...args: Parameters<(typeof urlService)['calcUrl']>) {
            const url = this.calcUrl(...args);
            if (this.isDifferentUrl(url)) {
                this.history.push(url);
            }
        }

        /** Replaces the current url. */
        replaceUrl(...args: Parameters<(typeof urlService)['calcUrl']>) {
            const url = this.calcUrl(...args);
            if (this.isDifferentUrl(url)) {
                this.history.replace(url);
            }
        }

        isDifferentUrl(url: string) {
            return url !== this.history.location.pathname + this.history.location.search + this.history.location.hash;
        }

        resetUrl() {
            this.history.push('/');
        }

        /**
         * Removes the most recent history items up until historyIndex
         * @param historyIndex all history items up to this index will be cleared
         */
        removeFromBackHistory(historyIndex: number) {
            if (!this.store) {
                console.error('no store was set');
                return;
            }
            this.store.dispatch(removeFromBackHistory(historyIndex));
        }

        connectUrlToStore(store: { dispatch: (action: any) => void }) {
            this.store = store;
            if (this.cancelListener) this.cancelListener();
            const updateState = () => store.dispatch(urlChanged(this.getUrl()));
            this.cancelListener = this.history.listen(updateState);
            updateState();
        }

        useHashBase(hashBase: string) {
            useEffect(() => {
                this.hashBase.set(hashBase);

                return () => {
                    this.hashBase.set(undefined);
                };
            }, [hashBase]);
        }

        isInCurrentHasBase(path: string) {
            path = path.replace(/\/+$/, '');
            const hashBase = this.hashBase.get()?.replace(/\/+$/, '') ?? this.history.location.pathname.split('/')[1] ?? '';

            return path === hashBase || path.startsWith(hashBase + '/');
        }

        resolve(base: string, ...path: string[]) {
            return URLUtils.resolve(base, ...path);
        }

        urlToString({ path, params, hash }: UrlState) {
            let result = path;
            if (!_.isEmpty(params)) {
                result += `?${this.stringify(params)}`;
            }
            if (!_.isEmpty(hash)) {
                result += `#${this.stringify(hash)}`;
            }
            return result;
        }
    },
);
