import { Store } from '@whiz-cart/node-shared/models/store';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { authService } from './auth.service';
import { calcAssignableRoles } from './authHelpers';
import { calcActiveRoles } from '@whiz-cart/ui-shared/auth/calcActiveRoles';
import { ScopeParameters } from '@whiz-cart/ui-shared/auth/authTypes';
import { calcHasClaim } from '@whiz-cart/ui-shared/auth/calcHasClaim';

export function useAuth() {
    const loginState = authService.loginState.useState();
    const { stores, isLoaded: areStoresLoaded } = useSelector(
        (state: any) => state.stores as { stores: Record<string, Store>; isLoaded?: boolean },
    );
    const activeStoreGuid = useSelector((state: any) => state.url.params.storeGuid as string | undefined);
    const [roleHierarchy = {}] = authService.roleHierarchy.useAction(undefined);
    const isAuthLoading = !areStoresLoaded || !loginState.claimMapping;

    const getActiveRoles = useMemo(() => calcActiveRoles(loginState, stores, activeStoreGuid), [loginState, stores, activeStoreGuid]);

    const hasRole = useCallback(
        (roles: string | string[], ...scope: ScopeParameters) => {
            const activeRoles = getActiveRoles(...scope);
            if (!Array.isArray(roles)) {
                roles = [roles];
            }

            return activeRoles.has('admin') || roles.some((role) => activeRoles.has(role));
        },
        [getActiveRoles],
    );

    const hasClaim = useMemo(() => calcHasClaim(loginState, stores, activeStoreGuid), [loginState, stores, activeStoreGuid]);

    const getAssignableRoles = useMemo(() => calcAssignableRoles(roleHierarchy), [roleHierarchy]);

    return { ...loginState, getActiveRoles, hasRole, hasClaim, getAssignableRoles, isAuthLoading };
}
