import { ReactCreateTranslatorOptions, type FlatKeys, type FlattenDict } from 'schummar-translate/react';
import en from './languages/en';

export type Dict = FlattenDict<typeof en>;
export type TranslationKeys = FlatKeys<typeof en>;

const warningsSeen = new Set<string>();

export const commonTranslatorConfig = {
    sourceLocale: 'en',
    sourceDictionary: en,
    fallbackLocale: ['de', 'en'],
    ignoreMissingArgs: true,

    warn: (locale, id) => {
        const key = `${locale}:${id}`;
        if (warningsSeen.has(key)) return;
        warningsSeen.add(key);
        console.warn('Missing translation:', locale, key);
    },

    dateTimeFormatOptions: {
        dateStyle: 'medium',
        timeStyle: 'medium',
    },
} satisfies ReactCreateTranslatorOptions<{}>;
