import { urlService } from '@whiz-cart/ui-shared/url/url.service';
import productBrowserService from '@/productBrowser/productBrowser.service';
import { checkForChromeResetCode } from '@/util/chromeResetCode';
import { barcodeClassificationService } from '@/barcodeClassification/barcodeClassification.service';
import { BarcodeClassificationResult } from '@whiz-cart/node-shared/models/barcodeClassification/barcodeClassificationResult';

type RouteScannedCodeResult = 'productNoStoreGuid';

export async function routeScannedCode(gtin: string): Promise<void | RouteScannedCodeResult> {
    if (!gtin) return;

    const url = urlService.getUrl();
    const path = url.path.toLocaleLowerCase();

    if (path.match(/mhd/)) {
        urlService.pushUrl(`/product/details/${gtin}/mhd`);
        return;
    }

    if (path.match('/integritycheck/details/') || path.match('/checkout/payment/')) {
        document.dispatchEvent(new CustomEvent('integrityCheckEvent', { detail: gtin }));
        return;
    }

    if (checkForChromeResetCode(gtin)) {
        return;
    }

    if (gtin.toLowerCase().includes('/integritycheck/details/')) {
        urlService.pushUrl(gtin);
        return;
    }

    const params = urlService.getUrlParams();
    const classification = await barcodeClassificationService.classify(params?.storeGuid ?? null, gtin).get();
    return routeScannedCodeClassification(gtin, classification, params?.storeGuid);
}

export async function routeScannedCodeClassification(
    gtin: string,
    classification: BarcodeClassificationResult,
    storeGuid?: string,
): Promise<void | RouteScannedCodeResult> {
    switch (classification.type) {
        case 'customerLogin':
            urlService.pushUrl(`/customer`, { userGuid: classification.properties.deviceId });
            break;
        case 'customerAppSupportCode':
            urlService.pushUrl(`/customer`, { userGuid: classification.properties.userGUID });
            break;
        case 'product': {
            if (!storeGuid) {
                return 'productNoStoreGuid';
            }

            try {
                const product = await productBrowserService.getProductByBarcode(storeGuid, gtin);
                const itemId = typeof product === 'object' && product !== null && 'itemId' in product ? product.itemId : 'unknown';
                urlService.pushUrl(`/productBrowser/${itemId}`);
            } catch {
                console.warn('You may have scanned a User QR Code:', gtin);
            }
            break;
        }
        default:
            console.warn(`Unhandled classification result type "${classification.type}":`, classification);
    }
}
