export async function loadPaged<T = unknown>(
    load: (
        skip: number,
        limit: number,
    ) => Promise<{
        items: T[];
        totalItemCount?: number;
    }>,
    limit = 100,
) {
    const items: T[] = [];
    let totalItemCount = Infinity;
    let skip = 0;
    let page;

    do {
        page = await load(skip, limit);
        items.push(...page.items);
        totalItemCount = page.totalItemCount ?? Infinity;
        skip += limit;
    } while (page.items.length === limit && items.length < totalItemCount);

    return items;
}
