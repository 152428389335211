import { z } from 'zod';
import { ParsedBoolean } from '../parsed/parsedBoolean';

export type StoreGeneralConfig = z.infer<typeof StoreGeneralConfig>;

export const StoreGeneralConfig = z.object({
    isRwwsStore: z.string().nullish(),
    storeId: z.string().nullish(),
    storeInventoryManagementSystem: z.string().nullish(),
    storeMapFancyFile: z.string().nullish(),
    storeMapFancyBounds: z.string().nullish(),
    storeMapFile: z.string().nullish(),
    storeMapBounds: z.string().nullish(),
    featureNewStoreLayout: z.string().nullish(),
    featureSessionSurveillanceEnabled: z.string().nullish(),
    useNewDebitCreditImagesAtCheckout: z.string().nullish(),
    offlinePaymentsHideButton: z.string().nullish(),
    branding: z.string().nullish(),
    enableEdekaApp: z.string().nullish(),
    enableManualRandomCheck: z.string().nullish(),
    dsiClassificationDetectionThreshold: z.string().nullish(),
    promotionTemplateURL: z.string().nullish(),
    productImportTempalteURL: z.string().nullish(),
    duplicatePaymentCheck: ParsedBoolean.nullish(),
    enableCustomerMark: ParsedBoolean.nullish(),
    enableCouponsHistory: ParsedBoolean.nullish(),
    enableCurrency: z.string().nullish().default('EUR'),
});
