import { errorToString } from '@whiz-cart/node-shared/errorToString';
import { request } from '@whiz-cart/ui-shared/request/request';
import { service } from '@whiz-cart/node-shared/service/service';
import jwtDecode from 'jwt-decode';
import localforage from 'localforage';
import { Action, Store, StorePersist } from 'schummar-state/react';
import { v4 as uuid } from 'uuid';
import { z } from 'zod';
import storeService from '../store/store.service';
import endpoint from '../util/endpoint';
import { resolveEndpoint } from '../util/resolveEndpoint';
import { AccessTokenRaw, LoginState, RoleScope } from '@whiz-cart/ui-shared/auth/authTypes';

class AuthService {
    constructor() {
        this.persist.initialization.then(() => {
            this.loginState.update((state) => {
                state.isLoggedIn = !!state.refreshToken;
            });

            this.claimMapping.subscribe(undefined, (claimMapping) => {
                if (claimMapping) {
                    this.loginState.update((state) => {
                        state.claimMapping = claimMapping;
                    });
                }
            });
        });
    }

    loginState = new Store<LoginState>({});

    private storage = localforage.createInstance({ name: 'auth_v3' });
    private persist = new StorePersist(this.loginState, this.storage);

    accessToken = new Action(
        async () => {
            try {
                const accessToken = await this.fetchAccessToken();

                this.loginState.update((state) => {
                    state.tokenDetails = this.decodeToken(accessToken);
                });

                return accessToken;
            } catch (e: any) {
                if (e.response?.status === 401) {
                    this.logout();
                }

                const msg = errorToString(e);
                throw new Error(`Failed to get accessToken: ${msg}`);
            }
        },
        {
            invalidateAfter: (token) => {
                if (!token) {
                    return 10_000;
                }

                const { exp = 0 } = this.decodeToken(token);
                const timeLeft = exp * 1000 - Date.now();
                return Math.max(timeLeft * 0.9, 0);
            },
        },
    );

    claimMapping = new Action(
        async () => {
            const response = await endpoint('storeManager.getClaims').get();
            const parsed = z
                .record(
                    z.record(
                        z.object({
                            allowAnyScope: z.boolean().catch(false),
                            mustBeEnabledForStore: z.boolean().catch(false),
                            claim: z.string(),
                            roles: z.string().array(),
                        }),
                    ),
                )
                .parse(response);

            return new Map(
                Object.values(parsed).flatMap((claims) => {
                    return Object.entries(claims).map(([key, value]) => [
                        key,
                        {
                            ...value,
                            roles: new Set(value.roles),
                        },
                    ]);
                }),
            );
        },
        { invalidateAfter: 60 * 60 * 1000 },
    );

    roleHierarchy = new Action(
        async () => {
            const response = await endpoint('storeManager.getRoles').get();
            return z.record(z.string().array()).parse(response);
        },
        { invalidateAfter: 60 * 60 * 1000 },
    );

    async login(userName: string, password: string, turnstileResponse?: string, testEnvironmentSecretToken?: string) {
        try {
            const headers: Record<string, string> = {};
            if (typeof testEnvironmentSecretToken === 'string') {
                headers['x-test-environment'] = testEnvironmentSecretToken;
            }

            const response: { accessToken: string; refreshToken: string } = await request.post(
                resolveEndpoint('storeManager.login'),
                {
                    userName,
                    password,
                    claims: await this.getLoginClaims(),
                    turnstileResponse,
                },
                { headers },
            );

            await this.acceptLogin(response);
        } catch (e) {
            console.error('Failed to login', e);
            throw e;
        }
    }

    /** Usually use authService.accessToken instead!
     * This fetches a new token ignoring the cache.
     */
    async fetchAccessToken(forceReloadCache?: boolean) {
        if (forceReloadCache) {
            this.persist.stop();
            this.persist = new StorePersist(this.loginState, this.storage);
        }

        await this.persist.initialization;
        const { refreshToken } = this.loginState.getState();

        if (!refreshToken) {
            console.debug('No refreshToken available. Could not fetch accessToken');
            throw Error('No refreshToken available. Could not fetch accessToken');
        }

        try {
            const { accessToken }: { accessToken: string } = await request.post(resolveEndpoint('storeManager.refresh'), {
                refreshToken,
                claims: await this.getLoginClaims(),
            });

            return accessToken;
        } catch (e: any) {
            console.error('Failed to retreive accessToken', e.response?.status ?? '<no status>', e);
            throw e;
        }
    }

    async acceptLogin({ accessToken, refreshToken }: { accessToken: string; refreshToken: string }) {
        this.loginState.update((state) => {
            state.refreshToken = refreshToken;
            state.tokenDetails = this.decodeToken(accessToken);
        });

        Action.clearCacheAll();
        this.accessToken.setCache(undefined, accessToken);

        await Promise.all([
            this.claimMapping.get(undefined),
            storeService.allStores.get(undefined),
        ]);

        this.loginState.update((state) => {
            state.isLoggedIn = true;
        });

        console.debug('Logged in.');
    }

    logout() {
        this.loginState.update((state) => {
            state.isLoggedIn = false;
            state.refreshToken = undefined;
            state.tokenDetails = undefined;
            this.accessToken.clearCacheAll();
        });
        console.debug('Logged out.');
    }

    async changePassword(userName: string, oldPassword: string, newPassword: string) {
        try {
            await request.post(resolveEndpoint('storeManager.changePassword'), {
                userName,
                password: oldPassword,
                newPassword,
            });
        } catch (e) {
            console.error('Failed to change password', e);
            throw e;
        }
    }

    async getLoginClaims() {
        await this.persist.initialization;
        let deviceId = this.loginState.getState().deviceId;

        if (!deviceId) {
            deviceId = uuid();
            this.loginState.update((state) => {
                state.deviceId = deviceId;
            });
        }

        return { deviceId };
    }

    decodeToken(accessToken: string) {
        const raw: AccessTokenRaw = jwtDecode(accessToken);

        return {
            ...raw,
            roles: typeof raw.roles === 'string' ? JSON.parse(raw.roles) : (raw.roles ?? []),
        };
    }

    assumeRole(role: string | RoleScope) {
        this.loginState.update((state) => {
            state.tokenDetails!.roles = [typeof role === 'string' ? { scope: 'global', roles: [role] } : role];
        });
    }
}

export const authService = service('authService', AuthService);
