import { HTMLProps, ReactNode, useEffect, useRef } from 'react';

export interface ClickOutsideProps extends HTMLProps<HTMLDivElement> {
    children?: ReactNode;
    onClickOutside?: (e: MouseEvent) => void;
    onClickCaptureOutside?: (e: MouseEvent) => void;
}

export function ClickOutside({ children, onClickOutside, onClickCaptureOutside, ...divProps }: ClickOutsideProps) {
    const ref = useRef<HTMLDivElement>(null);
    const clickHandlersRef = useRef({ onClickOutside, onClickCaptureOutside });

    useEffect(() => {
        clickHandlersRef.current = { onClickOutside, onClickCaptureOutside };
    });

    useEffect(() => {
        const onClick = (e: MouseEvent) => {
            if (e.target instanceof Node && !ref.current?.contains(e.target)) {
                clickHandlersRef.current.onClickOutside?.(e);
            }
        };

        const onClickCapture = (e: MouseEvent) => {
            if (e.target instanceof Node && !ref.current?.contains(e.target)) {
                clickHandlersRef.current.onClickCaptureOutside?.(e);
            }
        };

        document.addEventListener('click', onClick);
        document.addEventListener('click', onClickCapture, true);

        return () => {
            document.removeEventListener('click', onClick);
            document.removeEventListener('click', onClickCapture, true);
        };
    }, []);

    return (
        <div {...divProps} ref={ref}>
            {children}
        </div>
    );
}
