import { Brand, BrandImages, type BrandIcons } from '@whiz-cart/node-shared/models/brand/brand';
import { DealType } from '@whiz-cart/node-shared/models/deals/easyDeals';
import { createContext, useCallback, useContext } from 'react';
import { useBrand } from '../brand/brandContext';
import disruptorDefaultImages from '../disruptor/defaultImages';

export interface ImgSrcAsset {
    asset: string;
}
export const isImgSrcAsset = (src: ImgSrc): src is ImgSrcAsset => (src as ImgSrcAsset).asset !== undefined;

export interface ImgSrcProductImage {
    product: { itemId?: string | null; capacity?: number | null; gtin?: string | null; storeGuid?: string };
    flags?: string[] | null;
    size?: 's' | 'l' | 'm';
}
export const isImgSrcProductImage = (src: ImgSrc): src is ImgSrcProductImage => (src as ImgSrcProductImage).product !== undefined;

export interface ImgSrcFlyer {
    flyer: { relativePath?: string; pageCount?: number; extension?: string };
}

export const isImgSrcFlyer = (src: ImgSrc): src is ImgSrcFlyer => (src as ImgSrcFlyer).flyer !== undefined;
export interface ImgSrcDeal {
    couponId?: string | null;
    dealType?: DealType | null;
    imageUrl?: string | null;
    itemId?: string | null;
    size: 's' | 'l';
}

export const isImgSrcDeal = (src: ImgSrc): src is ImgSrcDeal => !!(src as ImgSrcDeal).couponId;

export interface ImgSrcStoreMap {
    storeMap: string;
}
export const isImgSrcStoreMap = (src: ImgSrc): src is ImgSrcStoreMap => (src as ImgSrcStoreMap).storeMap !== undefined;

export interface ImgSrcRecipeImage {
    recipeImage: string;
    width: number;
    height?: number;
}
export const isImgSrcRecipeImage = (src: ImgSrc): src is ImgSrcRecipeImage => (src as ImgSrcRecipeImage).recipeImage !== undefined;

export interface ImgSrcPromotion {
    promotionAsset: string;
}
export const isImgSrcPromotion = (src: ImgSrc): src is ImgSrcPromotion => (src as ImgSrcPromotion).promotionAsset !== undefined;

export interface ImgSrcAdvertisement {
    advertisement: string;
}
export const isImgSrcAdvertisement = (src: ImgSrc): src is ImgSrcAdvertisement => (src as ImgSrcAdvertisement).advertisement !== undefined;

export type ImgSrcBrandImage = { brandImage: keyof BrandImages | keyof BrandIcons } | { brandImageUrl: string };
export const isImgSrcBrandImage = (src: ImgSrc): src is ImgSrcBrandImage =>
    src instanceof Object && ('brandImage' in src || 'brandImageUrl' in src);
export type ImgSrcSnapshotImage = { snapshotImageUrl: string; storeGuid: string };
export const isImgSrcSnapshotImage = (src: ImgSrc): src is ImgSrcSnapshotImage =>
    (src as ImgSrcSnapshotImage).snapshotImageUrl != undefined;

export interface ImgSrcSupportInfo {
    supportInfo: string;
}
export const isImgSrcSupportInfo = (src: ImgSrc): src is ImgSrcSupportInfo => (src as ImgSrcSupportInfo).supportInfo !== undefined;

export interface ImgSrcDisruptorImage {
    disruptorImage: string;
}
export const isImgSrcDisruptorImage = (src: ImgSrc): src is ImgSrcDisruptorImage =>
    (src as ImgSrcDisruptorImage).disruptorImage !== undefined;

export interface ImgSrcDigitalStamp {
    digitalStampUrl: string;
}
export const isImgSrcDigitalStamp = (src: ImgSrc): src is ImgSrcDigitalStamp => (src as ImgSrcDigitalStamp).digitalStampUrl !== undefined;

export interface ImgSrcDsiImage {
    dsiSnapshotImageUrl: string;
    storeGuid: string;
}

export const isImgSrcDsiImage = (src: ImgSrc): src is ImgSrcDsiImage => (src as ImgSrcDsiImage).dsiSnapshotImageUrl !== undefined;

export type ImgSrc =
    | string
    | ImgSrcAsset
    | ImgSrcProductImage
    | ImgSrcStoreMap
    | ImgSrcRecipeImage
    | ImgSrcPromotion
    | ImgSrcAdvertisement
    | ImgSrcFlyer
    | ImgSrcDeal
    | ImgSrcBrandImage
    | ImgSrcSnapshotImage
    | ImgSrcSupportInfo
    | ImgSrcDisruptorImage
    | ImgSrcDigitalStamp
    | ImgSrcDsiImage
    | undefined;

export interface ImgSrcConfig {
    url?: string;
    token?: string;
    preserveAdUrl?: boolean;
    brandImageUrl?: string;
    snapshotImageUrl?: string;
    supportInfoUrl?: string;
    promotionsUrl?: string;
    storeGuid?: string;
}

export const ImgSrcContext = createContext<ImgSrcConfig | null>(null);

export function useImgSrc() {
    const config = useContext(ImgSrcContext);
    const brand = useBrand();

    if (!config) {
        throw new Error('ImgSrcContext is not provided');
    }

    return useCallback(resolveImgSrc(config, brand), [config, brand]);
}

function resolveImgSrc(config: ImgSrcConfig, brand: Brand): (src: ImgSrc) => string | undefined {
    function resolve(url: string) {
        if (!config.url) return undefined;
        let result = `${config.url}/${url}`;
        if (config.token) result += `?${config.token}`;
        return result;
    }

    const resolveProductImage = (src: ImgSrcProductImage) => {
        const { capacity, gtin } = src.product || {};
        let { itemId } = src.product || {};
        const storeGuid = src.product?.storeGuid || config.storeGuid;
        if (itemId === undefined) return;

        if (
            itemId?.match(/-ST$/) &&
            src.flags?.map?.((s) => s.toLocaleLowerCase()).includes('isdrink') &&
            ((capacity && capacity > 1) || gtin?.length === 4) //
        ) {
            itemId = itemId.replace(/-ST$/, '-KOA');
        }

        let width = 0;
        let height = 0;

        switch (src.size) {
            case 's':
                width = 130;
                height = 98;
                break;
            case 'm':
                width = 250;
                height = 250;
                break;
            case 'l':
            default:
                width = 380;
                height = 380;
                break;
        }

        if (!storeGuid) {
            return resolve(`api/images/v2/${itemId}/${width}x${height}`);
        }

        return resolve(`api/v3/products/${storeGuid}/${itemId}/${width}x${height}`);
    };

    const resolveRecipeImage = (src: ImgSrcRecipeImage) => {
        const recipeImage = src.recipeImage.replace(/https:\/\/[^/]+\/+(media\/+)?/, '');
        return resolve(`api/recipes/v1/${src.width}x${src.height ?? ''}/${recipeImage}`);
    };

    const resolveAdvertisement = (src: ImgSrcAdvertisement) => {
        if (config.preserveAdUrl) return src.advertisement;
        const [, path] = src.advertisement.match(/^https?:\/\/[^/]*\/(.*)$/) || [];
        if (!path) return;
        return resolve(path);
    };

    return (src: ImgSrc) => {
        if (!src) return src;

        if (typeof src === 'string') {
            return src;
        }

        if (isImgSrcAsset(src)) {
            return resolve(src.asset);
        }

        if (isImgSrcProductImage(src)) {
            return resolveProductImage(src);
        }

        if (isImgSrcDeal(src)) {
            if (src.imageUrl) {
                if (src.dealType === 'genussPlus') {
                    return resolve(src.imageUrl);
                } else {
                    return resolve(`api/couponImage/${src.couponId}`);
                }
            }

            const s: ImgSrcProductImage = {
                product: {
                    itemId: src.itemId,
                },
                size: src.size ?? 'l',
            };
            return resolveProductImage(s);
        }

        if (isImgSrcStoreMap(src)) {
            return resolve(`api/stores/v1/${src.storeMap}`);
        }

        if (isImgSrcFlyer(src)) {
            const {
                flyer: { pageCount, extension, relativePath },
            } = src;
            if (!pageCount || !extension || !relativePath) return;
            const pageCountString = String(pageCount).padStart(2, '0');
            const url = `flyer/${relativePath}/${pageCountString}.${extension}`;
            return resolve(url);
        }

        if (isImgSrcRecipeImage(src)) {
            return resolveRecipeImage(src);
        }

        if (isImgSrcPromotion(src)) {
            return resolve(`api/promotions/v1/${src.promotionAsset}`);
        }

        if (isImgSrcAdvertisement(src)) {
            return resolveAdvertisement(src);
        }

        if (isImgSrcBrandImage(src)) {
            let url =
                'brandImageUrl' in src
                    ? src.brandImageUrl
                    : (brand.images[src.brandImage as keyof BrandImages] ?? brand.icons[src.brandImage as keyof BrandIcons]);

            if (!url || url.match(/^\/|blob:|data:/)) {
                return url ?? '';
            }

            url = stripPrefix(url);

            if (config.brandImageUrl) {
                return `${config.brandImageUrl}/${url}`;
            }

            return resolve(`branding/${url}`);
        }

        if (isImgSrcSnapshotImage(src)) {
            const url = src.snapshotImageUrl;
            if (src.storeGuid === undefined) {
                throw Error('storeGuid is missing for snapshot image');
            }
            return `${config.snapshotImageUrl}/camera-images/${src.storeGuid}${url}`;
        }

        if (isImgSrcSupportInfo(src)) {
            return `${config.supportInfoUrl}/${src.supportInfo}`;
        }

        if (isImgSrcDisruptorImage(src)) {
            if (src.disruptorImage.startsWith('default://')) {
                const id = src.disruptorImage.replace('default://', '');
                return disruptorDefaultImages[id];
            }

            if (config?.promotionsUrl) {
                return `${config.promotionsUrl}/${src.disruptorImage}`;
            }

            return resolve(`${src.disruptorImage}`);
        }

        if (isImgSrcDigitalStamp(src)) {
            return resolve(`digitalStamp/${src.digitalStampUrl}`);
        }

        if (isImgSrcDsiImage(src)) {
            const url = src.dsiSnapshotImageUrl;
            if (src.storeGuid === undefined) {
                throw Error('storeGuid is missing for dsi image');
            }
            return resolve(`dsiImage/${src.storeGuid}${url}`);
        }

        throw Error(`Unknown src type: ${JSON.stringify(src)}`);
    };
}

function stripPrefix(url: string) {
    return url.replace(/https:\/\/[^/]+\//, '');
}
