import { Global } from '@emotion/react';
import { createTheme, Theme, ThemeProvider, useTheme, type ThemeOptions } from '@mui/material';
import { Brand } from '@whiz-cart/node-shared/models/brand/brand';
import { defaultsDeep, omit } from 'lodash';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useImgSrc } from '../img/imgSrc';
import { defaultBrand, defaultBrands } from './defaultBrand';

export const brandContext = createContext<Brand>(defaultBrand);

export function createBrandTheme(baseTheme: Theme, brand: Brand) {
    return createTheme(baseTheme as ThemeOptions, omit(brand, '_id', 'images'), {
        shape: {
            borderRadius: brand.borderRadius,
        },
    });
}

export function BrandProvider({
    brand,
    children,
    defaultBrandId = 'easyshopper',
}: {
    brand?: Brand;
    children?: ReactNode;
    defaultBrandId?: string;
}) {
    const selectedDefaultBrand = defaultBrands[defaultBrandId] ?? defaultBrand;
    const resolvedBrand = useMemo(() => {
        const mergedBrand = defaultsDeep({}, brand, selectedDefaultBrand);
        if (brand && (brand.customerIds?.length ?? 0) > 0) {
            mergedBrand.customerIds = brand.customerIds;
        }
        return mergedBrand;
    }, [brand, selectedDefaultBrand]);

    const baseTheme = useTheme();
    const theme = useMemo(() => createBrandTheme(baseTheme, resolvedBrand), [baseTheme, resolvedBrand]);

    return (
        <brandContext.Provider value={resolvedBrand}>
            <ThemeProvider theme={theme}>
                <CssVarProvider>{children}</CssVarProvider>
            </ThemeProvider>
        </brandContext.Provider>
    );
}

export function CssVarProvider({ children }: { children: ReactNode }) {
    const resolveImgSrc = useImgSrc();
    const brand = useBrand();
    const theme = useTheme();

    const vars = useMemo(() => {
        const palette = Object.fromEntries(
            Object.entries(theme.palette).flatMap(([key, props]) => {
                if (typeof props === 'object' && props !== null) {
                    return Object.entries(props).map(([subKey, value]) => [`--palette-${key}-${subKey}`, value as any]);
                }

                if (typeof props === 'function') {
                    return [];
                }

                return [[`--palette-${key}`, props]];
            }),
        );

        if (brand.headerUsePrimaryColor) {
            palette['--palette-headerBackground-main'] = 'var(--palette-primary-main)';
            palette['--palette-headerBackground-light'] = 'var(--palette-primary-light)';
            palette['--palette-headerBackground-dark'] = 'var(--palette-primary-dark)';
            palette['--palette-headerBackground-contrastText'] = 'var(--palette-primary-contrastText)';
        }

        const images = Object.fromEntries(
            Object.entries(brand.images).map(([key, image]) => [
                `--images-${key}`,
                image ? `url("${resolveImgSrc({ brandImageUrl: image }) ?? ''}")` : 'none',
            ]),
        );

        const customerIds = Object.fromEntries(
            brand.customerIds?.flatMap((customerId) => {
                return [
                    [`--customerId-${customerId.id}-color-main`, customerId?.color ? customerId.color.main : 'none'],
                    [`--customerId-${customerId.id}-color-contrastText`, customerId.color.contrastText],
                    [
                        `--customerId-${customerId.id}-image`,
                        customerId.image ? `url("${resolveImgSrc({ brandImageUrl: customerId.image }) ?? ''}")` : 'none',
                    ],
                ];
            }) ?? [],
        );

        const primaryCustomerId =
            brand.customerIds?.find((cId) => cId.type === 'card' && cId.showOnWelcomeScreen) ?? brand.customerIds?.[0] ?? null;
        if (primaryCustomerId) {
            customerIds['--customerIdPrimary-color-main'] = primaryCustomerId.color.main;
            customerIds['--customerIdPrimary-color-contrastText'] = primaryCustomerId.color.contrastText;
            customerIds['--customerIdPrimary-image'] = primaryCustomerId.image
                ? `url("${resolveImgSrc({ brandImageUrl: primaryCustomerId.image }) ?? ''}")`
                : 'none';
        }

        const extraProperties = {
            '--border-radius': typeof brand.borderRadius === 'number' ? `${brand.borderRadius}px` : brand.borderRadius,
            '--header-search-btn': brand.headerUsePrimaryColor ? theme.palette.primary.dark : theme.palette.primary.main,
            '--header-sos-btn': brand.headerUsePrimaryColor ? theme.palette.primary.main : 'white',
            '--header-selected-button-background': brand.headerUsePrimaryColor ? theme.palette.primary.dark : 'white',
            '--payment-header-background': brand.headerUsePrimaryColor ? theme.palette.primary.main : 'white',
            '--payment-cartId-background': brand.headerUsePrimaryColor ? theme.palette.primary.dark : theme.palette.primary.main,
            '--product-tile-v1': brand.priceLayoutAlt ? 'none' : 'block',
            '--product-tile-v2': brand.priceLayoutAlt ? 'flex' : 'none',
            '--amountConfigurator-background': brand.priceLayoutAlt ? theme.palette.primary.main : '#eee',
            '--search-bar-border-radius': brand.priceLayoutAlt ? '4px' : '20px',
            '--price-preview-v1': brand.priceLayoutAlt ? 'none' : 'flex',
            '--price-preview-v2': brand.priceLayoutAlt ? 'flex' : 'none',
            '--header-total-display': brand.headerDisplayTotal ? 'flex' : 'none',
            '--header-bubble-display': brand.headerDisplayTotal ? 'none' : 'block',
            '--header-cart-padding': brand.headerDisplayTotal ? '0' : '1rem',
            '--font-family-alternative':
                brand.fontFamily ||
                "'Fira Sans', 'Noto Sans JP Variable', 'Noto Sans SC Variable', 'Noto Sans TC Variable', 'Noto Sans Thai Variable', sans-serif",
            '--deals-layout-default': brand.promotionsLayoutAlt ? 'none' : 'flex',
            '--deals-layout-alternative': brand.promotionsLayoutAlt ? 'flex' : 'none',
        };

        return {
            ':root': {
                ...palette,
                ...images,
                ...customerIds,
                ...extraProperties,
            },
        };
    }, [theme]);

    return (
        <>
            <Global styles={vars} />

            {children}
        </>
    );
}

export function useBrand() {
    return useContext(brandContext);
}

export function UseBrand({ children }: { children: (brandAssets: ReturnType<typeof useBrand>) => ReactNode }) {
    const brand = useBrand();

    return <>{children(brand)}</>;
}
