import { Brand } from '@whiz-cart/node-shared/models/brand/brand';
import defaultCartBackground from './assets/background.jpg';
import defaultLoginTutorial01 from './assets/loginTutorial01.png';
import defaultLoginTutorial02 from './assets/loginTutorial02.png';
import defaultLoginTutorial03 from './assets/loginTutorial03.png';
import defaultLogoBlack from './assets/logoBlack.svg';
import defaultLogoColor from './assets/logoColor.svg';
import defaultLogoCompany from './assets/logoCompany.svg';
import logoPayback from './assets/logoPayback.png';
import easyAppQrCode from './assets/easy-app-qr-code.svg';
import defaultLogoWhite from './assets/logoWhite.svg';
import { resolveBrandPalette } from './resolveBrand';

export const defaultBrand = {
    _id: 'easyshopper',
    palette: resolveBrandPalette({
        primary: {
            main: '#84bd00',
            light: '#bcd47d',
            dark: '#60742f',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#0d99ff',
            light: '#70c3ff',
            dark: '#00497e',
            contrastText: '#ffffff',
        },
        discount: {
            main: '#d30c2a',
            contrastText: '#ffffff',
        },
        free: {
            main: '#84bd00',
            contrastText: '#ffffff',
        },
        cartBackground: {
            main: '#333333',
            light: '#d9d9d9',
            dark: '#d9d9d9',
            contrastText: '#ffffff',
        },
        navigationBackground: {
            main: '#333333',
            light: '#646464',
            dark: '#333333',
            contrastText: '#ffffff',
        },
    }),
    images: {
        loginTutorial01: defaultLoginTutorial01,
        loginTutorial02: defaultLoginTutorial02,
        loginTutorial03: defaultLoginTutorial03,
        logoBlack: defaultLogoBlack,
        logoWhite: defaultLogoWhite,
        logoColor: defaultLogoColor,
        logoCompany: defaultLogoCompany,
        cartBackground: defaultCartBackground,
    },
    icons: {},
    customerIds: [
        {
            id: 'easy-app',
            name: 'EASY App',
            image: easyAppQrCode,
            color: {
                main: '#003EB0',
                contrastText: '#ffffff',
            },
            pointsText: null,
            showOnWelcomeScreen: true,
            type: 'mobileApp',
        },
        {
            id: 'PB',
            name: 'PAYBACK',
            image: logoPayback,
            color: {
                main: '#003EB0',
                contrastText: '#ffffff',
            },
            pointsText: '+{points}° P',
            showOnWelcomeScreen: true,
            type: 'card',
        },
    ],
    borderRadius: 5,
    cartBrand: 'EASY Shopper',
    cartBrandShort: 'EASY',
    storeBrand: 'EDEKA',
    loyaltyCard: 'PAYBACK',
} as const satisfies Brand;

export const defaultBrandV2 = {
    _id: 'cartUI_v2',
    palette: resolveBrandPalette({
        primary: {
            main: '#84bd00',
            light: '#bcd47d',
            dark: '#60742f',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#0d99ff',
            light: '#70c3ff',
            dark: '#00497e',
            contrastText: '#ffffff',
        },
        discount: {
            main: '#d30c2a',
            contrastText: '#ffffff',
        },
        free: {
            main: '#84bd00',
            contrastText: '#ffffff',
        },
        cartBackground: {
            main: '#1c1c1c',
            light: '#ffffff',
            dark: '#363636',
            contrastText: '#ffffff',
        },
        navigationBackground: {
            main: '#333333',
            light: '#646464',
            dark: '#333333',
            contrastText: '#ffffff',
        },
    }),
    images: {
        loginTutorial01: defaultLoginTutorial01,
        loginTutorial02: defaultLoginTutorial02,
        loginTutorial03: defaultLoginTutorial03,
        logoBlack: defaultLogoBlack,
        logoWhite: defaultLogoWhite,
        logoColor: defaultLogoColor,
        logoCompany: defaultLogoCompany,
    },
    icons: {},
    customerIds: [
        {
            id: 'easy-app',
            name: 'EASY App',
            image: easyAppQrCode,
            color: {
                main: '#003EB0',
                contrastText: '#ffffff',
            },
            pointsText: null,
            showOnWelcomeScreen: true,
            type: 'mobileApp',
        },
        {
            id: 'PB',
            name: 'PAYBACK',
            image: logoPayback,
            color: {
                main: '#003EB0',
                contrastText: '#ffffff',
            },
            pointsText: '+{points}° P',
            showOnWelcomeScreen: true,
            type: 'card',
        },
    ],
    borderRadius: 5,
} as const satisfies Brand;

export const defaultBrands: Record<string, Brand> = {
    easyshopper: defaultBrand,
    cartUI_v2: defaultBrandV2,
};
