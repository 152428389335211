export function service<Service>(name: string, constructor: new () => Service): Service;
export function service<Service>(arg0: string | (new () => Service), arg1?: new () => Service): Service {
    let name;
    let constructor;
    if (typeof arg0 === 'string') {
        if (!arg1) throw Error('Missing argument: constructor');
        name = arg0;
        constructor = arg1;
    } else {
        name = arg0.name;
        constructor = arg0;
    }

    name = name.slice(0, 1).toLocaleLowerCase() + name.slice(1);

    const instance = new constructor();
    if (typeof (globalThis as any).window !== 'undefined') (globalThis as any).window[name] = instance;
    return instance;
}
