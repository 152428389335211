import { ActionButton } from '@/components/actionButton';
import { useTranslator } from '@/translate';
import userService from '@/user/user.service';
import { faSignsPost } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, TextField, Typography } from '@mui/material';
import { Img } from '@whiz-cart/ui-shared/img/img.component.js';
import { urlService } from '@whiz-cart/ui-shared/url/url.service';
import { commonValidations } from '@whiz-cart/ui-shared/validations/commonValidations';
import { createForm } from 'cross-state/react';
import { Link } from 'react-router-dom';

interface ForgotPasswordType {
    email: string;
}

export const passwordResetForm = createForm<ForgotPasswordType, ForgotPasswordType>({
    defaultValue: { email: '' },
    validations: {
        email: {
            required: (value) => !!value,
            invalidEmail: commonValidations.email,
        },
    },
});

export default function ForgotPassword() {
    const t = useTranslator();

    return (
        <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <passwordResetForm.Form
                localizeError={(error) => {
                    switch (error) {
                        case 'required':
                            return t('validations.required');
                        case 'invalidEmail':
                            return t('validations.email');
                        default:
                            return error;
                    }
                }}
            >
                <ForgotPasswordInner />
            </passwordResetForm.Form>
        </div>
    );
}

function ForgotPasswordInner() {
    const t = useTranslator();
    const form = passwordResetForm.useForm();

    async function save() {
        if (!form.isValid()) {
            return;
        }

        const draft = form.getDraft();
        await userService.forgotPassword(draft.email);
    }

    return (
        <>
            <Link to={urlService.calcUrl('/')} css={{ position: 'absolute', top: 0, left: 0, margin: 'var(--big-margin)' }}>
                <Img data-testid="logo" src={{ brandImage: 'logoBlack' }} css={{ height: '60px' }} />
            </Link>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0,
                    maxWidth: 600,
                    margin: 'auto',
                }}
            >
                <FontAwesomeIcon icon={faSignsPost} size="8x" />

                <div css={{ margin: 'var(--large-margin)', marginTop: 'var(--margin)' }}>
                    <Typography variant="h1" component="h1" textAlign="center">
                        {t('profile.forgotPassword.title')}
                    </Typography>

                    <Typography component="p" textAlign="center">
                        {t('profile.forgotPassword.message')}
                    </Typography>
                </div>

                <passwordResetForm.Field
                    name="email"
                    render={(props, { hasTriggeredValidations, errors }) => (
                        <TextField
                            {...props}
                            value={props.value ?? ''}
                            onChange={(e) => props.onChange(e.target.value)}
                            error={hasTriggeredValidations && errors.length > 0}
                            label={t('profile.email')}
                            type="email"
                            autoComplete="email"
                            fullWidth
                            variant="outlined"
                        />
                    )}
                />

                <ActionButton variant="contained" type="submit" fullWidth sx={{ mt: 2 }} onClick={save}>
                    {t('profile.forgotPassword.submit')}
                </ActionButton>
            </Box>
        </>
    );
}
