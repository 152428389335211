import '@/util/doNotUseInWorker';

import { commonTranslatorConfig, type Dict } from '@/translations/commonTranslatorConfig';
import currentBrand from '@/util/currentBrand';
import { configureTranslations } from '@whiz-cart/ui-shared/translate';
import orderBy from 'lodash/orderBy';
import { createTranslator } from 'schummar-translate/react';
import meta from './translations/languages/generated/meta.json';

export type { Dict, TranslationKeys } from '@/translations/commonTranslatorConfig';

const dicts: Record<string, () => Promise<Partial<Dict>>> = Object.fromEntries(
    Object.entries(import.meta.glob('./translations/languages/generated/*.ts', { import: 'default' })).map(([key, value]) => {
        const locale = key.match(/([^/]*)\.ts$/)![1]!;

        return [
            locale,
            async () => value() as Promise<Partial<Dict>>,
        ];
    }),
);

export const { t, useTranslator, getTranslator, clearDicts } = createTranslator({
    ...commonTranslatorConfig,

    dicts,

    provideArgs: {
        cartBrand: {
            get: () => currentBrand.get()?.cartBrand ?? 'Whiz-Cart',
            subscribe: (callback) => currentBrand.subscribe(callback),
        },
        cartBrandShort: {
            get: () => currentBrand.get()?.cartBrandShort ?? 'Whiz-Cart',
            subscribe: (callback) => currentBrand.subscribe(callback),
        },
        storeBrand: {
            get: () => currentBrand.get()?.storeBrand ?? 'Supermarket',
            subscribe: (callback) => currentBrand.subscribe(callback),
        },
        loyaltyCard: {
            get: () => currentBrand.get()?.loyaltyCard ?? 'Loyalty Card',
            subscribe: (callback) => currentBrand.subscribe(callback),
        },
    },
});
// @ts-expect-error incompatible types
configureTranslations({ t, useTranslator, getTranslator, clearDicts });

const AdditionalProperties: { [code: string]: { region?: string } } = {
    de: { region: 'DE' },
    en: { region: 'US' },
    'en-CA': {},
    'fr-CA': {},
    fr: { region: 'FR' },
    nb: { region: 'NO' },
    ro: { region: 'RO' },
    th: { region: 'TH' },
};

export const LanguageMeta = {
    ...meta,
    languages: orderBy(
        meta.languages.map((language) => {
            const code = [language.language, language.region].filter(Boolean).join('-');
            return {
                code,
                ...language,
                ...AdditionalProperties[code],
            };
        }),
        (language) => {
            const index = Object.keys(AdditionalProperties).indexOf(language.code);
            return index >= 0 ? index : Infinity;
        },
    ),
};

export const browserLocale = dicts[navigator.language] ? navigator.language : navigator.language.slice(0, 2);
