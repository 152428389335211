import { z } from 'zod';

export const ParsedTrue = z.preprocess((x) => {
    if (typeof x === 'string' && ['true', 't', '1'].includes(x.toLowerCase())) {
        return true;
    }

    return x;
}, z.literal(true));

export const ParsedFalse = z.preprocess((x) => {
    if (typeof x === 'string' && ['false', 'f', '0'].includes(x.toLowerCase())) {
        return false;
    }

    return x;
}, z.literal(false));

export const ParsedBoolean = z.union([ParsedTrue, ParsedFalse]);
