import { ReactNode } from 'react';
import { SwipeableProps, useSwipeable } from 'react-swipeable';
import css from './swipeable.module.less';
import c from 'classnames';

export function Swipeable({
    children,
    className,
    testId,
    ...props
}: SwipeableProps & { children?: ReactNode; className?: string; testId?: string }) {
    const handlers = useSwipeable(props);

    return (
        <div data-testid={testId} className={c(className, css.swipe)} {...handlers}>
            {children}
        </div>
    );
}
