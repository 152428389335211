import endpoint from '@/util/endpoint';
import { Brand } from '@whiz-cart/node-shared/models/brand/brand';
import { service } from '@whiz-cart/node-shared/service/service';
import { defaultBrand } from '@whiz-cart/ui-shared/brand/defaultBrand';
import { createCache, createResourceGroup } from 'cross-state';
import { orderBy } from 'lodash';
import { z } from 'zod';
import { BrandDraft } from './types/brandDraft';

export const brandEditorService = service(
    'brandEditorService',
    class BrandEditorService {
        rg = createResourceGroup();

        list = createCache(
            async (): Promise<Brand[]> => {
                try {
                    const response = await endpoint('storeManager.brandingList').get();
                    const brands = Brand.array().parse(response);

                    return [defaultBrand, ...orderBy(brands, '_id')];
                } catch (e) {
                    console.error('Failed to fetch brands:', e);
                    throw e;
                }
            },
            { resourceGroup: this.rg },
        );

        get = createCache(
            async (brandId: string): Promise<Brand | undefined> => {
                try {
                    if (brandId === 'easyshopper') {
                        // default brand is built in
                        return undefined;
                    }

                    if (!this.list.state.get().isStale) {
                        const fromList = this.list.state.get().value?.find((brand) => brand._id === brandId);
                        if (fromList) {
                            return fromList;
                        }
                    }

                    const response = await endpoint('storeManager.brandingGet', { brandId }).get();
                    const brand = Brand.parse(response);
                    return brand;
                } catch (e) {
                    console.error('Failed to fetch brand:', e);
                    throw e;
                }
            },
            { resourceGroup: this.rg },
        );

        async save(brand: BrandDraft, oldId?: string) {
            if (brand._id !== oldId) {
                const allBrands = await this.list.get();
                if (allBrands.some((other) => other._id === brand._id)) {
                    throw Object.assign(new Error(`Id is already taken: ${brand._id}`), { type: 'duplicateId' });
                }
            }

            const imagesPromises = Object.entries(brand.images).map(async ([key, image]) => {
                return [key, image instanceof File ? await this.uploadImage(image) : image] as [string, string | null];
            });
            const iconPromises = Object.entries(brand.icons).map(async ([key, icon]) => {
                return [key, icon instanceof File ? await this.uploadImage(icon) : icon] as [string, string | null];
            });
            const customerIsPromises = (brand.customerIds ?? []).map(async (card) => {
                if (card.image instanceof File) {
                    card.image = await this.uploadImage(card.image);
                }
                return card;
            });

            const images = Object.fromEntries(await Promise.all(imagesPromises));
            const icons = Object.fromEntries(await Promise.all(iconPromises));
            const customerIds = await Promise.all(customerIsPromises);

            if (oldId && oldId !== brand._id) {
                await endpoint('storeManager.brandingRename').post({
                    oldId,
                    newId: brand._id,
                });
            }

            await endpoint('storeManager.brandingUpdate', { id: brand._id }).post({
                ...brand,
                images,
                icons,
                customerIds,
            });

            this.get(brand._id).updateValue(brand as Brand);
            this.rg.invalidateAll();
        }

        async uploadImage(image: File) {
            const formData = new FormData();
            formData.append('file', image);

            const response = await endpoint('storeManager.brandingUpload').post(formData);
            const url = z.string().parse(response);

            return url;
        }

        async delete(id: string) {
            await endpoint('storeManager.brandingDelete', { id }).delete();

            this.rg.invalidateAll();
        }
    },
);
