// @ts-nocheck
import CPromise from '@whiz-cart/node-shared/cPromise';
import { Button } from '@whiz-cart/ui-shared/button/button.component.tsx';
import { Form } from '@whiz-cart/ui-shared/form/form.component.jsx';
import { Input } from '@whiz-cart/ui-shared/form/input.component.jsx';
import { NavLink } from '@whiz-cart/ui-shared/routing/navLink.component.tsx';
import { commonValidations } from '@whiz-cart/ui-shared/validations/commonValidations';
import { Turnstile, TurnstileInstance } from '@marsidev/react-turnstile';
import _ from 'lodash';
import { useRef, useState } from 'react';
import { authService } from '../auth/auth.service';
import { t, useTranslator } from '../translate';
import css from './login.module.less';
import inputCss from '@whiz-cart/ui-shared/form/input.module.less';
import { RequestError } from '@whiz-cart/ui-shared/request/request';
import { z } from 'zod';
import { urlService } from '@whiz-cart/ui-shared/url/url.service';

// Used for E2E tests, see: https://developers.cloudflare.com/turnstile/troubleshooting/testing/
const TURNSTILE_TEST_ENVIRONMENT_SITE_KEY = '1x00000000000000000000AA';

const validations = (changePassword) => ({
    userName: commonValidations.required,
    password: commonValidations.required,
    newPassword: changePassword && commonValidations.password,
    newPasswordRepeat: changePassword && commonValidations.sameAs('newPassword'),
});

const errorCodes = {
    wrongPassword: 34005,
    invalidStoreToken: 34010,
    wrongStoreToken: 34011,
    noStoreToken: 34012,
    changePassword: 34004,
};

export default function Login({ props }: any) {
    const [changePassword, setChangePassword] = useState(false);
    const request = useRef(null);

    const turnstileRef = useRef<TurnstileInstance>();
    const [turnstileToken, setTurnstileToken] = useState('');
    const isTurnstileProcessing = (config?.turnstileCaptcha.siteKey ?? '').length > 0 && turnstileToken.length === 0;

    const testEnvironmentSecretToken = urlService.getUrlHashParams().testEnvironment;

    async function handleLogin({ userName, password, newPassword }, { setError, setSuccess }) {
        if (!userName.value) {
            setError('userName', t('profile.login.noUserName'));
            return;
        }
        if (!password.value) {
            setError('password', t('profile.login.noPassword'));
            return;
        }

        // Reset error overrides, otherwise errors will still be visible
        setError('username', undefined);
        setError('password', undefined);
        setError('captcha', undefined);

        try {
            request.current?.cancel();

            if (changePassword) {
                const error = await (request.current = new CPromise((resolve, reject) =>
                    authService.changePassword(userName.value, password.value, newPassword.value).then(resolve).catch(reject),
                ));

                if (error) {
                    if (error.data?.status === 34005) {
                        setError('password', 'Wrong password');
                    } else setError('password', 'Unknnown Error');
                    return;
                }
            }

            const error = await (request.current = new CPromise((resolve, reject) =>
                authService
                    .login(userName.value, newPassword.value || password.value, turnstileToken, testEnvironmentSecretToken)
                    .then(resolve)
                    .catch(reject),
            ));

            if (error) {
                throw error;
            }
        } catch (error) {
            console.error('Failed to login', error);
            // Captcha is only valid once -> reset, so user can try again ASAP
            turnstileRef.current?.reset();

            switch (error.response?.status) {
                case 401: {
                    if (error instanceof RequestError) {
                        const result = z
                            .object({
                                isCaptchaSuccessful: z.boolean(),
                            })
                            .safeParse(error.data);

                        if (result.success && !result.data.isCaptchaSuccessful) {
                            setError('captcha', t('profile.login.captchaInvalidOrExpired'));
                            return;
                        }
                    }

                    setError('password', t('profile.login.wrongPasswordOrUser'));
                    break;
                }

                default:
                    if (error) {
                        const knownError = _.findKey(errorCodes, (x) => x === error.data?.status);
                        if (knownError === 'changePassword') {
                            setSuccess('You have to change your password.');
                            setChangePassword(true);
                        } else if (knownError) setError('password', t(`profile.login.${knownError}`));
                        else setError('password', t('profile.login.unknownError'));
                    } else setError('password', 'Unknown Error');
            }
        }
    }

    const trans = useTranslator();
    const validationMessages = {
        userName: `${trans('profile.login.requiredField')}`,
        password: `${trans('profile.login.requiredField')}`,
        newPassword: (x) => `${trans('profile.login.minimumLength', { currentLength: x.length })}`,
        newPasswordRepeat: `${trans('profile.login.passwordRepeat')}`,
    };

    return (
        <Form
            testId="loginForm"
            className={css.form}
            successClassName={css.changePasswordMessage}
            values={props}
            action={handleLogin}
            validations={validations(changePassword)}
            validationMessages={validationMessages}
        >
            {({ userName, password, newPassword, newPasswordRepeat, captcha }, { processing, submit }) => (
                <>
                    <div className={css.title}>{t('profile.login.title')}</div>

                    <div className={css.label}>{t('profile.login.username')}</div>
                    <Input
                        {...userName}
                        onChange={changePassword ? undefined : userName.onChange}
                        className={css.input}
                        type="text"
                        autoComplete="username"
                    />
                    {changePassword && (
                        <input value={userName.value} type="text" autoComplete="username" style={{ display: 'none' }} readOnly />
                    )}

                    <div className={css.label}>{changePassword ? t('profile.login.oldPassword') : t('profile.login.password')}</div>
                    <Input {...password} className={css.input} type="password" autoComplete="current-password" />

                    {changePassword && (
                        <>
                            <div className={css.label}>{t('profile.login.newPassword')}</div>
                            <Input {...newPassword} className={css.input} type="password" autoComplete="new-password" />

                            <div className={css.label}>{t('profile.login.repeatNewPassword')}</div>
                            <Input {...newPasswordRepeat} className={css.input} type="password" autoComplete="new-password" />
                        </>
                    )}

                    <div className={css.captcha}>
                        {config?.turnstileCaptcha.siteKey && (
                            <Turnstile
                                ref={turnstileRef}
                                siteKey={
                                    typeof testEnvironmentSecretToken === 'string'
                                        ? TURNSTILE_TEST_ENVIRONMENT_SITE_KEY
                                        : config.turnstileCaptcha.siteKey
                                }
                                options={{ theme: 'light', size: 'flexible', action: 'login' }}
                                onSuccess={setTurnstileToken}
                            />
                        )}
                        <div className={inputCss.validationErrorLabel}>{captcha.validationError}</div>
                    </div>

                    <Button
                        data-testid="loginButton"
                        className={css.button}
                        primary
                        inactive={processing || isTurnstileProcessing}
                        spinner={processing}
                        onClick={submit}
                    >
                        {t('profile.login.login')}
                    </Button>

                    <NavLink to="/forgotPassword" className={css.forgotPassword}>
                        {t('profile.forgotPassword.link')}
                    </NavLink>
                </>
            )}
        </Form>
    );
}
