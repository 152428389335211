import { appState } from '@/appState';
import { brandEditorService } from '@/brandEditor/brandEditor.service';
import storeService from '@/store/store.service';
import { Brand } from '@whiz-cart/node-shared/models/brand/brand';
import type { StoreGeneralConfig } from '@whiz-cart/node-shared/models/config/storeGeneralConfig';
import { urlService } from '@whiz-cart/ui-shared/url/url.service';
import { createStore, type Cancel } from 'cross-state';

const currentBrand = createStore<Brand | undefined>(undefined, {
    effect(store) {
        let storeGuid: string | undefined,
            config: StoreGeneralConfig | undefined,
            brand: Brand | undefined,
            overrideBrand: Brand | undefined;

        function update() {
            store.set(overrideBrand ?? brand);
        }

        let cancelStoreService: Cancel | undefined, cancelBrandEditorService: Cancel | undefined;

        const cancelUrlService = urlService.state.map('params.storeGuid').subscribe((_storeGuid) => {
            storeGuid = _storeGuid;
            cancelStoreService?.();

            cancelStoreService = storeService.getConfig.subscribe(storeGuid, (_config) => {
                config = _config;
                cancelBrandEditorService?.();

                if (!config?.branding) {
                    update();
                    return;
                }

                brandEditorService
                    .get(config.branding)
                    .get()
                    .catch(() => undefined);

                cancelBrandEditorService = brandEditorService
                    .get(config.branding)
                    .state.map('value')
                    .subscribe((_brand) => {
                        brand = _brand;
                        update();
                    });
            });
        });

        const cancelAppState = appState.subscribe(
            (x) => x.overrideBrand,
            (_overrideBrand) => {
                overrideBrand = _overrideBrand;
                update();
            },
        );

        return () => {
            cancelUrlService?.();
            cancelStoreService?.();
            cancelBrandEditorService?.();
            cancelAppState?.();
        };
    },
});

export default currentBrand;
Object.assign(window, { currentBrand });
