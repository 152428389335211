import { KnownError, useErrorMessage } from '@/error/useMessage';
import { LoadingButton } from '@mui/lab';
import { Checkbox, CircularProgress, ListItemButton, ListItemIcon, ListItemText, type ListItemButtonProps } from '@mui/material';
import { MaybePromise } from '@whiz-cart/node-shared/maybePromise';
import { useProcessing } from '@whiz-cart/ui-shared/hooks/useProcessing';
import { isPromise } from '@whiz-cart/node-shared/isPromise';
import { createStore } from 'cross-state';
import React, { forwardRef, type ComponentType, type ReactNode } from 'react';

export interface ActionButtonProps {
    actionId?: string;
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => MaybePromise<unknown>;
    knownErrors?: KnownError[];
    loading?: boolean;
    disabled?: boolean;
    concurrent?: boolean;
}

export const actionsInProgress = createStore<Set<unknown>>(new Set());

export function withActionInProgress<T>(actionId: string, action: () => T): T {
    actionsInProgress.update((state) => {
        state.add(actionId);
    });

    const cleanup = () =>
        actionsInProgress.update((state) => {
            state.delete(actionId);
        });

    try {
        const result = action();

        if (isPromise(result)) {
            result.finally(cleanup);
        } else {
            cleanup();
        }

        return result;
    } catch (error) {
        cleanup();
        throw error;
    }
}

function build<TProps extends { loading?: boolean; disabled?: boolean }>(Component: ComponentType<TProps>) {
    return forwardRef<HTMLButtonElement, Omit<TProps, 'actionId' | 'onClick' | 'knownErrors'> & ActionButtonProps>((props, ref) => {
        const { actionId, onClick, knownErrors, loading, disabled, concurrent, ...componentProps } = props as Omit<
            TProps,
            'actionId' | 'onClick' | 'knownErrors'
        > &
            ActionButtonProps; // TODO fix

        const { actionInPrgress, someActionInProgress } = actionsInProgress.useStore((state) => {
            return {
                actionInPrgress: state.has(actionId),
                someActionInProgress: state.size > 0,
            };
        });

        const [execute, inProgress, error] = useProcessing(async (e: React.MouseEvent<HTMLButtonElement>) => {
            if (actionsInProgress.get().size > 0) {
                return;
            }

            const id = actionId ?? {};
            actionsInProgress.update((state) => {
                state.add(id);
            });

            try {
                await onClick(e);
            } finally {
                actionsInProgress.update((state) => {
                    state.delete(id);
                });
            }
        });
        useErrorMessage(error, knownErrors);

        return (
            <Component
                ref={ref}
                onClick={execute}
                {...(componentProps as unknown as TProps)}
                loading={loading ?? (inProgress || actionInPrgress)}
                disabled={disabled ?? loading ?? (!concurrent && someActionInProgress)}
            />
        );
    });
}

export const ActionButton = build(LoadingButton);

export interface ListItemActionButtonProps extends Omit<ListItemButtonProps, 'children'> {
    icon?: ReactNode;
    label?: ReactNode;
    checked?: boolean;
    loading?: boolean;
}

export const ListItemActionButton = build(function ListItemActionButton({
    icon,
    label,
    checked,
    loading,
    ...props
}: ListItemActionButtonProps) {
    if (checked !== undefined) {
        icon ??= <Checkbox size="small" edge="start" checked={checked} tabIndex={-1} disableRipple />;
    }
    return (
        <ListItemButton {...props}>
            {icon && <ListItemIcon>{loading ? <CircularProgress size="1em" /> : icon}</ListItemIcon>}
            <ListItemText css={{ position: 'relative' }}>
                <span css={loading && !icon ? { opacity: 0 } : undefined}>{label}</span>
                {loading && !icon && (
                    <div
                        css={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress size="1em" sx={{ m: 0 }} />
                    </div>
                )}
            </ListItemText>
        </ListItemButton>
    );
});
