import store from '@/store';
import { routeScannedCode } from './routeScan';

type ScanHandler = (gtin: string) => void;

let handler: ScanHandler | undefined;

const HTML_TAG_BLOCKLIST = ['INPUT', 'TEXTAREA'] satisfies string[];

export default () => {
    let cancel: (() => void) | undefined;
    (async () => {
        const {
            cooldown = 100,
            commitKeys = [] as readonly string[],
            ignoreKeys = [] as readonly string[],
        } = await store.awaitState('config.scanner');

        let keys: string[] = [];
        let t = 0;

        const registerInput = (e: KeyboardEvent) => {
            // Ignore when typing in input or non-numerical keys
            if (HTML_TAG_BLOCKLIST.includes(document.activeElement?.nodeName ?? '')) {
                return;
            }

            const now = Date.now();
            if (now - t > cooldown) keys = [];

            if (commitKeys.includes(e.key) && keys.length) {
                const gtin = keys.join('');
                console.debug(`scan: ${gtin}`);

                if (handler) {
                    handler(gtin);
                } else {
                    routeScannedCode(gtin);
                }

                keys = [];
                e.preventDefault();
                if (e.target !== null && e.target instanceof HTMLElement) {
                    e.target.blur();
                }
            } else if (e.key?.length === 1 && !ignoreKeys.includes(e.key)) {
                keys.push(e.key);
                t = now;
            }
        };

        document.addEventListener('keydown', registerInput);
        cancel = () => document.removeEventListener('keydown', registerInput);

        (window as any).simulateScan = (text: string) => handler?.(text) ?? routeScannedCode(text);
    })();

    return () => cancel?.();
};

export const registerScanHandler = (newHandler: ScanHandler) => {
    handler = newHandler;
    return () => {
        if (handler === newHandler) handler = undefined;
    };
};
