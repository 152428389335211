import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autorenew as AutorenewIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogContent, DialogTitle, Skeleton } from '@mui/material';
import { ImperativeDialogProps } from '@/util/imperativeDialog';
import { errorToString } from '@whiz-cart/node-shared/errorToString';
import { useProcessing } from '@whiz-cart/ui-shared/hooks/useProcessing';
import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useStoreState } from 'schummar-state/react';
import { version } from '../../package.json';
import { t } from '../translate';
import { checkForUpdate, resetForceUpdate, updateState } from '../update/updateNotification';
import css from './serviceVersions.module.less';
import serviceVersionsService from './serviceVersions.service';
import { DialogCloseButton } from '@/components/dialogCloseButton';

export default function ServiceVersions({ open, onClose }: ImperativeDialogProps) {
    const backends = useSelector((state) => Object.keys(state.config.backends ?? {}));

    return (
        <Dialog open={open} onClose={() => onClose()} data-testid="versionOverlay">
            <DialogTitle>{t('versions.title')}</DialogTitle>

            <DialogContent>
                <div className={css.view}>
                    <SelfUpdate />

                    {backends.map((backend) => (
                        <BackendRow key={backend} backend={backend} />
                    ))}
                </div>
            </DialogContent>

            <DialogCloseButton onClose={onClose} />
        </Dialog>
    );
}

function SelfUpdate() {
    const { updateInProgress, updateReloadScheduled } = useStoreState(updateState);

    const [requestUpdate, isRequested] = useProcessing(() => checkForUpdate(true));

    useEffect(() => resetForceUpdate, []);

    const isLoading = isRequested || updateInProgress;

    return (
        <>
            <div className={css.label}>{t('versions.ui')}</div>
            <div className={css.value}>{version}</div>

            <LoadingButton
                onClick={() => {
                    if (updateReloadScheduled) {
                        window.location.reload();
                    } else {
                        requestUpdate();
                    }
                }}
                loading={isLoading}
                loadingPosition="start"
                variant="contained"
                className={css.checkForUpdate}
                startIcon={<AutorenewIcon />}
            >
                {isRequested
                    ? t('versions.checking')
                    : updateReloadScheduled
                      ? t('versions.scheduled')
                      : updateInProgress
                        ? t('versions.inProgress')
                        : t('versions.lookForUpdate')}
            </LoadingButton>
        </>
    );
}

function BackendRow({ backend }: { backend: string }) {
    const [version, { error }] = serviceVersionsService.getVersion.useAction(backend);

    return (
        <Fragment key={backend}>
            <div className={css.label}>{version?.app || backend}</div>
            <div className={css.value} data-testid={`version-${backend}`}>
                {version?.version ||
                    (error ? (
                        <FontAwesomeIcon className={css.error} fixedWidth icon={faTimes} title={errorToString(error)} />
                    ) : (
                        <Skeleton variant="rectangular" />
                    ))}
            </div>
        </Fragment>
    );
}
