import { z } from 'zod';
import { HistoryItem } from '../history';
import { OtherString } from '../otherString';
import { Prettify } from '../prettify';
import { AssetLocationKind } from './assetLocationKind';
import { AssetType } from './assetType';
import { Properties } from './properties';

export type Asset = Prettify<z.infer<typeof Asset>>;

export const Asset = z.object({
    id: z.string(),
    parentId: z.string().nullish(),
    barcode: z.string().nullish(),
    type: AssetType.or(OtherString).default(''),
    name: z.string().default(''),
    locationType: AssetLocationKind.or(OtherString).nullish(),
    locationId: z.string().nullish(),
    properties: Properties.nullish(),
    lifeCycle: z
        .object({
            start: z.coerce.date().nullish(),
            end: z.date().nullish(),
        })
        .nullish(),
    history: HistoryItem.array().nullish(),
});
