// @ts-nocheck
import checkoutService from '@/checkout/checkout.service';
import bus from '../connection/storeManagerConnection';
import store from '../store';
import {
    closeSession,
    fetchSessions,
    setDevicesLoading,
    updateDevice,
    updateDeviceLocations,
    updateTicket,
    updateWeights,
} from './sessionMonitor.action';
import {
    addTaraChange,
    addTicketChange,
    sessionScaleTrigger,
    sessionWeightDeviation,
    sessionWeightWithoutScan,
    sessionManualCheck,
} from './sessionMonitorDetails.action';
import { updatePayments } from '@/checkout/checkout.action';

export default () => [
    bus.onConnection(
        () => {
            if (!bus.config.storeGuid) return [];
            return store.dispatch(fetchSessions(bus.config.storeGuid));
        },
        (loading) => store.dispatch(setDevicesLoading(loading)),
    ),

    bus.subscribe('storeSessionStart', (msg) => {
        store.dispatch(updateDevice(msg));
    }),

    bus.subscribe('storeSessionTransferCompleted', (msg) => {
        store.dispatch(updateDevice(msg));
    }),

    bus.subscribe('storeSessionEnd', (msg) => {
        store.dispatch(closeSession(msg));
    }),

    bus.subscribe('deviceLocationUpdated', (msg) => {
        store.dispatch(updateDeviceLocations([msg]));
    }),

    bus.subscribe('deviceLocationBatchUpdate', (msg) => {
        store.dispatch(updateDeviceLocations(msg));
    }),

    bus.subscribe('ticketCreated', (msg) => {
        store.dispatch(updateTicket(msg));
        store.dispatch(addTicketChange(msg));
        if (msg?.category === 'taggedCustomer') {
            store.dispatch(fetchSessions(bus.config.storeGuid));
        }
    }),

    bus.subscribe('ticketChanged', (msg) => {
        store.dispatch(updateTicket(msg));
        store.dispatch(addTicketChange(msg));
    }),

    bus.subscribe('sessionMonitor_admin', (msg) => {
        store.dispatch(updateTicket(msg));
        store.dispatch(addTicketChange(msg));
    }),

    bus.subscribe('storeSessionTara', (item) => {
        store.dispatch(updateWeights({ ...item, ...item.tara, t: item.createdOn }));
        store.dispatch(addTaraChange(item));
    }),

    bus.subscribe('sessionScaleTrigger', (item) => {
        store.dispatch(updateWeights({ ...item, t: item.createdOn }));
        store.dispatch(sessionScaleTrigger(item));
    }),

    bus.subscribe('sessionWeightWithoutScan', (item) => {
        store.dispatch(updateWeights({ ...item, t: item?.model?.createdOn }));
        store.dispatch(sessionWeightWithoutScan(item));
    }),

    bus.subscribe('sessionWeightDeviation', (item) => {
        store.dispatch(updateWeights({ ...item, t: item.createdOn }));
        store.dispatch(sessionWeightDeviation(item));
    }),

    bus.subscribe('sessionManualCheck', (item) => {
        store.dispatch(sessionManualCheck(item));
    }),

    bus.subscribe('deviceExpectedWeightUpdated', (item) => {
        store.dispatch(
            updateWeights({
                sessionGuid: item.sessionGuid,
                t: item.weight?.ts,
                expectedWeight: item.weight?.weight,
            }),
        );
    }),

    bus.subscribe('deviceActualWeightUpdated', (item) => {
        store.dispatch(
            updateWeights({
                sessionGuid: item.sessionGuid,
                t: item.weight?.ts,
                actualWeight: item.weight?.weight,
            }),
        );
    }),

    bus.subscribe('customerPropertiesChanged', (msg) => {
        store.dispatch(fetchSessions(bus.config.storeGuid, msg?.customerGuid));
        checkoutService.getPendingRequests(bus.config.storeGuid).then((data) => store.dispatch(updatePayments(data)));
    }),
];
