import { useDebouncedValue } from '@whiz-cart/ui-shared/hooks/useDebouncedValue';
import equals from 'fast-deep-equal/es6/react';
import _ from 'lodash';
import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToTitleBar, removeFromTitleBar } from './titleBar.action';
import { v4 as genGuid } from 'uuid';
import { TitleBarProps } from './titleBar.component';

export type ProvidedSearch = { basePath: string; debounce?: boolean | number };
export type DirectSearch = { value: string; onChange: (value: string) => void };

export type TitleBarOptions = {
    /**
     * Higher priority takes precedence
     * @default 0
     */
    priority?: number;
    back?: TitleBarProps['back'];
    autoBack?: TitleBarProps['autoBack'];
    onlyKeepActionsWithLinkId?: TitleBarProps['onlyKeepActionsWithLinkId'];
    title?: React.ReactNode;
    colorBar?: string;
    collapse?: boolean;
    visibleActions?: number;
    breakActionsEarly?: boolean;
    search?: ProvidedSearch | DirectSearch;
    titleComponent?: JSX.Element;
    extraComponent?: React.ReactNode;
    isCustomerMarked?: boolean;
};

export const isProvidedSearch = (s: unknown): s is ProvidedSearch => {
    return s instanceof Object && ('basePath' in s || 'debounce' in s);
};
export const isDirectSearch = (s: unknown): s is DirectSearch => {
    return s instanceof Object && ('value' in s || 'onChange' in s);
};

const Depth = React.createContext(0);

export default <P extends { query?: string; [key: string]: any }>(params: TitleBarOptions | ((props: P) => TitleBarOptions)): any => {
    const _params = params instanceof Function ? params : () => params;

    return function (Wrapped: React.ComponentType<P>) {
        return function (props: P) {
            const paramsRef = useRef<TitleBarOptions>();
            const newParams = _params(props);
            if (!equals(paramsRef.current, newParams)) {
                paramsRef.current = newParams;
            }

            const guid = useRef(genGuid());
            const contextDepth = useContext(Depth);
            const depth = newParams.priority ?? contextDepth;
            const dispatch = useDispatch();

            useEffect(() => {
                const current = { guid, ...paramsRef.current, priority: depth };
                dispatch(addToTitleBar(current));

                return () => {
                    dispatch(removeFromTitleBar(current));
                };
            }, [paramsRef.current, depth]);

            return (
                <Depth.Provider value={depth + 1}>
                    <Wrapped {...props} />
                </Depth.Provider>
            );
        };
    };
};

export function useTitleBar(props: TitleBarOptions = {}) {
    const guid = useRef(genGuid());
    const propsRef = useRef(props);
    const dispatch = useDispatch();
    if (!equals(propsRef.current, props)) {
        propsRef.current = props;
    }

    const query = useSelector((state: any) => {
        if (isProvidedSearch(props.search)) {
            return state.url.path.replace(new RegExp(`${_.escapeRegExp(props.search.basePath)}/?`), '');
        } else if (isDirectSearch(props.search)) {
            return props.search.value;
        }
    });
    const debounce = isProvidedSearch(props.search) ? props.search.debounce : 0;
    const queryDebounced = useDebouncedValue(query, typeof debounce === 'number' ? debounce : debounce ? 500 : 0);

    useEffect(() => {
        const current = { guid, ...propsRef.current, priority: propsRef.current.priority ?? 0 };
        dispatch(addToTitleBar(current));

        return () => {
            dispatch(removeFromTitleBar(current));
        };
    }, [propsRef.current]);

    return { query: queryDebounced };
}
