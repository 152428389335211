import endpoint from '@/util/endpoint';
import { loadPaged } from '@/util/loadPages';
import {
    BarCodeClassification,
    serializeBarcodeClassification,
} from '@whiz-cart/node-shared/models/barcodeClassification/barcodeClassification';
import { RequestError } from '@whiz-cart/ui-shared/request/request';
import { service } from '@whiz-cart/node-shared/service/service';
import { createCache } from 'cross-state';
import { filter, orderBy } from 'lodash';
import { z } from 'zod';
import { BarcodeClassificationResult } from '@whiz-cart/node-shared/models/barcodeClassification/barcodeClassificationResult';

export type DropdownValuesItem = z.infer<typeof DropdownValuesItem>;
export type DropdownValues = z.infer<typeof DropdownValues>;

export const DropdownValuesItem = z
    .object({
        value: z.string(),
        description: z.string(),
    })
    .array();

export const DropdownValues = z.object({
    flags: DropdownValuesItem.default([]),
    types: DropdownValuesItem.default([]),
    customerMessageKeys: DropdownValuesItem.default([]),
    verificationLocalizationKeys: DropdownValuesItem.default([]),
    customerCardMetadataFlags: DropdownValuesItem.default([]),
});

export const barcodeClassificationService = service(
    'barcodeClassificationService',
    class BarcodeClassificationService {
        invalidateCaches(id?: string) {
            this.list.invalidateAll();
            this.classify.invalidateAll();
            this.findByCode.invalidateAll();

            if (id) {
                this.get(id).invalidate();
            } else {
                this.get.invalidateAll();
            }
        }

        list = createCache(
            async (storeGuid?: string) => {
                const response = await loadPaged((skip, limit) => endpoint('masterDataService.getAll', { skip, limit }).get());
                const items = BarCodeClassification.passthrough().array().parse(response);

                return orderBy(
                    storeGuid
                        ? filter(
                              items,
                              (item) =>
                                  item.target?.storeGuids?.includes(storeGuid) !== false &&
                                  !item.target?.excludedStoreGuids?.includes(storeGuid),
                          )
                        : items,
                    ['regex', 'relevance'],
                    ['asc', 'desc'],
                );
            },
            {
                invalidateAfter: { minutes: 5 },
            },
        );

        get = createCache(
            async (id: string) => {
                const response = await endpoint('masterDataService.getById', { id }).get();
                return BarCodeClassification.passthrough().parse(response);
            },
            {
                invalidateAfter: { minutes: 5 },
            },
        );

        classify = createCache(
            async (storeGuid: string | null, code: string) => {
                if (storeGuid) {
                    const response = await endpoint('storeManager.classifyCode', { storeGuid }).post(code);
                    return BarcodeClassificationResult.parse(response);
                }

                const response = await endpoint('storeManager.classifyCodeGlobal').post(code);
                return BarcodeClassificationResult.parse(response);
            },
            {
                invalidateAfter: { minutes: 5 },
            },
        );

        findByCode = createCache(
            async (storeGuid: string, code: string) => {
                try {
                    const response = await endpoint('storeManager.findByCode', { storeGuid, code }).get();
                    return response;
                } catch (error) {
                    if (error instanceof RequestError && error.response.status === 404) {
                        return null;
                    }
                    throw error;
                }
            },
            {
                invalidateAfter: { minutes: 5 },
            },
        );

        dropdownValues = createCache(async () => {
            const response = await endpoint('storeManager.getClassificationDropdownValues').get();
            return DropdownValues.parse(response);
        });

        async update(item: Partial<BarCodeClassification>): Promise<BarCodeClassification> {
            const body = serializeBarcodeClassification(item);
            const response = await endpoint('masterDataService.update').post(body);
            const updated = BarCodeClassification.passthrough().parse(response);

            this.invalidateCaches(updated.id);
            this.get(updated.id).updateValue(updated);
            return updated;
        }

        async delete(id: string): Promise<void> {
            await endpoint('masterDataService.delete', { id }).delete();

            this.list.invalidateAll();

            setTimeout(() => {
                this.get(id).clear();
            });
        }

        isExplicitEntry(item: Pick<BarCodeClassification, 'target'>, storeGuid?: string): boolean {
            return (!storeGuid || item.target?.storeGuids?.includes(storeGuid)) ?? false;
        }
    },
);
