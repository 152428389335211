import { z } from 'zod';

export const BarcodeClassificationResult = z.object({
    type: z.string(),
    raw: z.string(),
    properties: z
        .object({
            additionalFlags: z.unknown().array().nullish(),
        })
        .passthrough(),
});

export type BarcodeClassificationResult = z.infer<typeof BarcodeClassificationResult>;
