import { z } from 'zod';
import { Timestamp, Timestamped } from '../timestamped';
import { Target } from '../masterData/target';

export type BarCodeClassificationType = z.infer<typeof BarCodeClassificationType>;
export type BarcodeClassificationLookup = z.infer<typeof BarcodeClassificationLookup>;
export type BarcodeClassificationVerificationMessageOption = z.infer<typeof BarcodeClassificationVerificationMessageOption>;
export type BarcodeClassificationVerificationMessageOptions = z.infer<typeof BarcodeClassificationVerificationMessageOptions>;
export type BarcodeClassificationVerification = z.infer<typeof BarcodeClassificationVerification>;
export type BarcodeClassificationCustomerMessage = z.infer<typeof BarcodeClassificationCustomerMessage>;
export type BarcodeClassificationProductMetadata = z.infer<typeof BarcodeClassificationProductMetadata>;
export type BarcodeClassificationValidity = z.infer<typeof BarcodeClassificationValidity>;
export type SupportedPosProvider = z.infer<typeof SupportedPosProvider>;
export type BarcodeClassificationExternalAppMetadata = z.infer<typeof BarcodeClassificationExternalAppMetadata>;
export type BarcodeClassificationAssetMetadata = z.infer<typeof BarcodeClassificationAssetMetadata>;
export type BarCodeClassification = z.infer<typeof BarCodeClassification>;

export const BarCodeClassificationType = z.enum([
    'coupon',
    'product',
    'deposit',
    'customerCard',
    'employeeCard',
    'discountAbsolute',
    'discountPercentage',
]);

export const BarcodeClassificationLookup = z.object({
    gtin: z.string().nullish(),
    length: z.number().nullish(),
    prefix: z.string().nullish(),
    appendCheckDigit: z.boolean().nullish(),
    trimLeadingZero: z.boolean().nullish(),
});

export const BarcodeClassificationVerificationMessageOptionsEnum = {
    Message: 1,
    Product: 2,
    Barcode: 4,
    Silent: 8,
};

export const BarcodeClassificationVerificationMessageOption = z.enum(['Message', 'Product', 'Barcode', 'Silent']);

export const BarcodeClassificationVerificationMessageOptions = z.preprocess((x) => {
    if (typeof x === 'string') {
        return new Set(x.split(',').map((x) => x.trim()));
    }

    if (typeof x === 'number') {
        return new Set<string>(
            Object.entries(BarcodeClassificationVerificationMessageOptionsEnum)
                .filter(([, value]) => x & value)
                .map(([key]) => key),
        );
    }
    return x;
}, z.set(BarcodeClassificationVerificationMessageOption));

export const BarcodeClassificationVerification = z.object({
    options: BarcodeClassificationVerificationMessageOptions.nullish(),
    message: z.string().nullish(),
    canBeGrouped: z.boolean().nullish(),
    excludeFromPos: z.boolean().nullish(),
    posRequiresVerification: z.boolean().nullish(),
    localizationKey: z.string().nullish(),
    allowAppPayment: z.boolean().nullish(),
    skipShow: z.boolean().nullish(),
    skipVerification: z.boolean().nullish(),
});

export const CustomerCardMetadata = z.object({
    type: z.string().nullish(),
    flags: z.string().array().nullish(),
});

export const BarcodeClassificationCustomerMessage = z.object({
    messageKey: z.string().nullish(),
});

export const BarcodeClassificationProductMetadata = z.object({
    name: z.string().nullish(),
    icon: z.string().nullish(),
});

export const BarcodeClassificationValidity = z.object({
    validFrom: z.coerce.date().nullish(),
    validTo: z.coerce.date().nullish(),
});

export const BarcodeClassificationFlag = z.enum(['disableAdd', 'allowOneScan']);

export const SupportedPosProvider = z.enum(['mpos_v1', 'mpos_v2']);

export const BarcodeClassificationExternalAppMetadata = z.object({
    type: z.string().nullish(),
    supportedPosProviders: SupportedPosProvider.or(z.string() as z.ZodType<string & {}>)
        .array()
        .nullish(),
});

export const BarcodeClassificationAssetMetadata = z.object({
    properties: z.record(z.string(), z.union([z.string(), z.number(), z.boolean(), z.undefined()])),
});

export const BarCodeClassification = Timestamped.extend({
    id: z.string(),
    target: Target.nullish(),
    description: z.string().nullish(),
    relevance: z.number().nullish(),
    type: BarCodeClassificationType.or(z.string() as z.ZodType<string & {}>),
    regex: z.string().nullish(),
    lookup: BarcodeClassificationLookup.nullish(),
    verification: BarcodeClassificationVerification.nullish(),
    customerCardMetadata: CustomerCardMetadata.nullish(),
    customerMessage: BarcodeClassificationCustomerMessage.nullish(),
    productMetadata: BarcodeClassificationProductMetadata.nullish(),
    validity: BarcodeClassificationValidity.nullish(),
    flags: BarcodeClassificationFlag.or(z.string() as z.ZodType<string & {}>)
        .array()
        .nullish(),
    created: Timestamp.nullish(),
    modified: Timestamp.nullish(),
    deleted: Timestamp.nullish(),
    externalAppMetadata: BarcodeClassificationExternalAppMetadata.nullish(),
    assetMetadata: BarcodeClassificationAssetMetadata.nullish(),
});

export function serializeBarcodeClassificationVerificationMessageOptions(options: BarcodeClassificationVerificationMessageOptions): number {
    let result = 0;

    for (const option of options) {
        if (typeof option === 'string') {
            result |= BarcodeClassificationVerificationMessageOptionsEnum[option];
        }
    }

    return result;
}

export function serializeBarcodeClassification(barcodeClassification: Partial<BarCodeClassification>) {
    return {
        ...barcodeClassification,
        verification: barcodeClassification.verification && {
            ...barcodeClassification.verification,
            options: serializeBarcodeClassificationVerificationMessageOptions(barcodeClassification.verification?.options ?? new Set()),
        },
    };
}
